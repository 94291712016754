<template>
  <div class="page-index">
    <n-loader :loading="$var('load')" />
    <c-call-app v-if="isModal" :item="app" :show.sync="isModal"></c-call-app>
    <c-new-pin v-if="show" :show.sync="show" />
    <top-bar :title="$app.secure.clean($n.t('4.1'))" />
    <div v-if="user.isAdmin" class="container">
      <n-tabs :data="tabs" state>
        <template #tab="{item}">
          {{ item.title }}
        </template>
      </n-tabs>
      <n-tabs-content>
        <template #newOrder>
          <div class="new-order-wrap" :class="{'admin': user.isAdmin}">
            <div v-for="item in categories" :key="item.id" class="item-wrap new-style"
                 @click="$router.push({name: 'selectSub', params:{id: item.id}})"  :class="{'admin': user.isAdmin}">
              <div class="item new-style"  :class="{'admin': user.isAdmin}">
                <div class="img" v-html="item.image"></div>
                <div class="title">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </template>

        <template #orders>
          <div class="orders-wrap">
            <div v-for="item in applications" :key="item.id" class="item"
                 @click="$router.push({name: 'myApp', params: {id: item.id}})">
              <div>
                <div class="title">{{ item.name }}</div>
                <div class="volume">{{ item.volume }} кг</div>
              </div>
              <div class="date">
                {{ $app.date.format(item.createdAt, 'datetime') }}
                <n-icon icon="right-arrow" />
              </div>
            </div>
          </div>
        </template>
      </n-tabs-content>
    </div>
    <div v-else class="container">
      <n-tabs :data="userTabs" state>
        <template #tab="{item}">
          {{ item.title }}
        </template>
      </n-tabs>
      <n-tabs-content>
        <template #applications>
          <div class="new-order-wrap">
            <div v-for="item in applications" :key="item.id" class="item-wrap">
              <div class="item">
                <div class="image-place">
                  <div class="image">
                    <img :src="item.image" alt="">
                  </div>
                </div>
                <div class="title">{{ item.name }}</div>
                <div class="volume">{{ item.volume }} кг</div>
                <div class="address">{{ item.address }}</div>
                <div class="author">{{ item.author?.fullname }}</div>
                <div class="button-place">
                  <n-button class="call-button" icon="call" @click="openModal(item)"><t name="4.6" /></n-button>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #city>
          <div class="new-order-wrap">
            <n-select class="city-select" placeholder="Город" :value.sync="city" :data="cities"></n-select>
            <div v-for="item in applications" :key="item.id" class="item-wrap">
              <div class="item">
                <div class="image-place">
                  <div class="image">
                    <img :src="item.image" alt="">
                  </div>
                </div>
                <div class="title">{{ item.name }}</div>
                <div class="volume">{{ item.volume }} кг</div>
                <div class="address">{{ item.address }}</div>
                <div class="author">{{ item.author?.fullname }}</div>
                <div class="button-place">
                  <n-button class="call-button" icon="call" @click="openModal(item)"><t name="4.6" /></n-button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </n-tabs-content>
    </div>
  </div>
</template>

<script>
import img from 'assets/img.jpg'

export default {
  name: 'PageIndex',
  data() {
    return {
      img,
      isModal: false,
      user: $app.auth.user(),
      city: {},
      cities: [
        { value: '1', title: 'Алматы', },
      ],
      show: !localStorage.getItem('pin'),
      app: {},
      tabs: [
        { name: 'newOrder', title: $app.secure.clean($n.t('4.4')), },
        { name: 'orders', title: $app.secure.clean($n.t('4.5')), },
      ],
      userTabs: [
        { name: 'applications', title: $app.secure.clean($n.t('4.5')), },
        { name: 'city', title: $app.secure.clean($n.t('4.10')), },
      ],
      applications: [],
      categories: {},
      places: {
        1: 0,
        8: 2,
        13: 3,
        18: 4,
        21: 1,
      },
    }
  },
  computed: {
    tab() {
      return this.$route.query.tabsDefault
    },
  },
  watch: {
    city() {
      if (Object.keys(this.city).length !== 0) {
        this.loadApplication(this.city.value)
      }
    },
    tab() {
      if (this.tab === 'orders') {
        this.loadAuthorApp()
      }
    },
  },
  created() {
    if (this.user.isAdmin) {
      this.load()
    } else {
      this.loadApplication()
    }
    this.loadCities()
  },
  methods: {
    load() {
      this.$var('load', true)
      $api.categories.get().all().then((response) => {
        this.categories = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadCities() {
      this.$var('load', true)
      $api.cities.get().all().then((response) => {
        this.cities = $n.map(response.data.content, (e) => {
          return {
            value: e.id,
            title: e.name,
          }
        })
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadAuthorApp() {
      this.$var('load', true)
      $api.application.myApp().then((response) => {
        this.applications = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    openModal(item) {
      this.isModal = true
      this.app = item
    },
    loadApplication(cityId = 0) {
      this.$var('load', true)

      let api = $api.application.get().with('author')
      if (cityId !== 0) {
        api = api.filter({cityId: cityId})
      }

      api.then((response) => {
        this.applications = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-index {
  .image-place{
    display: flex;
    align-items: center;
    justify-content: center;
    .image {
      width: 125px;
      height: 75px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .city-select {
    margin-bottom: 25px;
  }
  .button-place {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    .call-button {
      margin-top: 10px;
      background-color: #52C41A;
      width: 132px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
    }
  }
  .category-title {
    font-size: 1.4em;
    padding: 10px;
    color: #102E4F;
  }

  .new-order-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -5px;
    &.admin {
      margin: 0 -5px;
    }


    .item-wrap {
      width: 50%;
      padding: 5px;
      display: flex;
      &.admin {
        padding: 0 5px;
        display: block;
      }
      &.new-style {
        width: 100%;
        margin-bottom: 15px;
      }

    }

    .item {
      background: #FFF;
      border: 1px solid #D9D9D9;
      border-radius: 12px;
      overflow: hidden;
      padding: 5px 5px 15px 5px;
      display: flex;
      flex-direction: column;
      &.admin {
        padding-bottom: 16px;
        flex-direction: row;
      }
      &.new-style {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: normal;
        padding: 10px;
        border: 2px solid var(--primary);
        .img {
          margin-top: 5px;
          height: 62px;
          width: 62px;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }

      .img {
        margin-top: 5px;
        height: 116px;
        margin-bottom: 16px;

        ::v-deep svg {
          height: 100%;
          width: 100%;
        }
      }

      .title{
        padding: 0 16px;
        font-weight: bold;
      }
      .volume{
        padding: 0 16px;
        font-weight: bold;
      }
      .address{
        padding: 0 16px;
        font-size: 14px;
      }
      .author {
        padding: 0 16px;
        font-weight: bold;
      }
    }
  }

  .orders-wrap{
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #D9D9D9;
      padding-bottom: 12px;
      margin-bottom: 12px;
      &:last-child{
        margin-bottom: 0;
      }

      .title{
        font-weight: 500;
      }

      .volume{
        font-weight: 400;
        font-size: 16px;
        color: #102E4F;
      }

      .date{
        font-weight: 400;
        font-size: 16px;
        color: #8C8C8C;

        .n-icon{
          margin-left: 16px;
        }
      }
    }
  }

  .title {
    font-weight: 400;
    font-size: 18px;
    color: #102E4F;
  }
}
</style>
